const LOGO = '/icons/logo.png';
const LOGO_WITH_TEXT_WHITE = '/icons/logo_with_text_white.png';
const LOGO_WITH_TEXT_BLACK = '/icons/logo_with_text_black.png';
const LOGO_CONVERSATION = '/icons/logo_conversation.png';
const LOGO_CONVERSATION_NARROW = '/icons/logo_conversation_narrow.png';
const WELL_RATED_TUTOR = '/icons/well_rated_tutor.png';
const WELL_RATED_TUTOR_WITH_CIRCLE = '/icons/findingTutor_with_circle.png';
const THREE_PEOPLE = '/icons/three_people.svg';
const TROPHY_WITH_CIRCLE = '/icons/trophy_with_circle.png';
const CALENDAR_WITH_CIRCLE = '/icons/calendar_with_circle.png';
const POLICY_ICON = '/icons/policy.png';
const LOCATION_ICON = '/icons/location.png';
const LOCATION_ICON_SVG = '/icons/location.svg';
const LEFT_ARROW_SVG = '/icons/left-arrow.svg';
const STUDENT_DESK_SVG = '/icons/student-desk.svg';
const TUTOR_BOARD_SVG = '/icons/tutor-blackboard.svg';
const SHARE_ICON = '/icons/share.png';
const SHIELD_ICON = '/icons/shield.svg';
const LIGHTBULB_ICON = '/icons/lightbulb.png';
const UPLOAD_FILE_BACKGROUND_SVG = '/icons/upload-file-background.svg';
const ACCOUNT_SVG = '/icons/account.svg';
const PHONE_SVG = '/icons/phone.svg';
const PHONE_WHITE_SVG = '/icons/phone_white.svg';
const EMAIL_SVG = '/icons/email.svg';
const ARROW_LEFT_SVG = '/icons/arrow-left.svg';
const ARROW_RIGHT_SVG = '/icons/arrow-right.svg';
const ARROW_LEFT_BLACK_SVG = '/icons/arrow-left-black.svg';
const ARROW_RIGHT_BLACK_SVG = '/icons/arrow-right-black.svg';
const ARROW_LEFT_DISABLED_SVG = '/icons/arrow-left-disabled.svg';
const ARROW_RIGHT_DISABLED_SVG = '/icons/arrow-right-disabled.svg';
const QUOTES_SVG = '/icons/quotes.svg';
const ARROW_DOWN = '/icons/arrow-down.svg';
const HEART_INLINE = '/icons/heart.svg';
const FACEBOOK = '/icons/social/fb.svg';
const LINKEDIN = '/icons/social/li.svg';
const INSTAGRAM = '/icons/social/ig.svg';
const TIK_TOK = '/icons/social/tiktok.svg';
const UPLOAD = '/icons/upload.svg';
const THREE_DOTS = '/icons/three-dots.svg';
const DOWNLOAD = '/icons/download.svg';
const GOLDENCUP = '/icons/goldencup.png';
const PAPERPLANE = '/icons/paperplane.svg';
const STAR = '/icons/star.png';
const TROPHY = '/icons/trophy.png';
const BADGE_SOLID = '/icons/badge-solid.png';
const CHANGE_ARROWS = '/icons/change-arrows.png';
const CREDIT_CARD = '/icons/credit-card-solid.png';
const PC_SOLID = '/icons/pc.png';
const GEAR_ICON = '/icons/gear_icon.png';
const BRAIN_ICON = '/icons/brain_icon.png';
const THREE_PEOPLE_ICON = '/icons/three_people_icon.png';
const RED_BLUE_DOT = '/icons/red_blue_dot.png';
const CHECK_ICON = '/icons/check.svg';
const CHECK_BLACK_ICON = '/icons/check_black.svg';
const CHECK_PRIMARY_ICON = '/icons/check-primary.svg';
const CHECKMARK_CIRCLE = '/icons/checkmark-circle.png';
const CHECKMARK_BADGE = '/icons/checkmark-badge.png';
const X_MARK_WHITE = '/icons/x_mark.svg';
const X_MARK = '/icons/X-mark.png';
const CHECK_ICON_PRIMARY = '/icons/check_primary_icon.png';
const GROUP_ICON_PRIMARY = '/icons/group_primary_icon.png';
const SCHOOL_ICON_PRIMARY = '/icons/school_primary_icon.png';
const SEARCH_CARD_ICON_PRIMARY = '/icons/search_card_primary_icon.png';
const SPEECH_ICON_PRIMARY = '/icons/speech_primary_icon.png';
const ISB_LOGO = '/icons/isb_logo.png';
const CALENDAR = '/icons/calendar.svg';
const GIVING = '/icons/giving.svg';
const GIVING_WHITE = '/icons/giving_white.svg';
const LAPTOP = '/icons/laptop.svg';
const LOGO_PREMIUM = '/icons/logo_premium.svg';
const DONATION_SUPPORT_BADGE = '/icons/donation_support_badge.png';
const CALENDAR2 = '/icons/elitetutor/calendar2.png';
const CERTIFICATE = '/icons/elitetutor/certificate.png';
const ELEARNING = '/icons/elitetutor/elearning.png';
const SETTINGS = '/icons/elitetutor/settings.png';
const STEPS = '/icons/elitetutor/steps.png';
const SUCCESS = '/icons/elitetutor/success.png';
const SWAP = '/icons/elitetutor/swap.png';
const WHITEBOARD = '/icons/elitetutor/whiteboard.png';
const THREE_WITH_FIVE_STARS = '/icons/elitetutor/three_with_five_stars.png';
const FLAG_DE = '/icons/flags/flag_de.svg';
const FLAG_EN = '/icons/flags/flag_en.svg';
const FLAG_UA = '/icons/flags/flag_ua.svg';
const EMOJI_PHONE = '/icons/emojis/phone-emoji.png';
const EMOJI_ROCKET = '/icons/emojis/rocket-emoji.png';
const REVIEW_PLATFORM_STARS = '/icons/stars-5.svg';
const REVIEW_PLATFORM_VERIFIED = '/icons/verified.svg';
const REGISTER_AS_ELITETUTOR_BADGE = '/icons/register-as-elitetutor/badge.svg';
const CHAT = '/icons/register-as-elitetutor/chat.svg';
const CLOCK = '/icons/register-as-elitetutor/clock.svg';
const PAY_CASH = '/icons/register-as-elitetutor/pay-cash.svg';
const SHAKE_HANDS = '/icons/register-as-elitetutor/shake-hands.svg';
const TALK = '/icons/register-as-elitetutor/talk.svg';
const THUMBS_DOWN = '/icons/register-as-elitetutor/thumbs-down.svg';
const THUMBS_UP = '/icons/register-as-elitetutor/thumbs-up.svg';
const WRITE_ON_COMPUTER = '/icons/register-as-elitetutor/write-on-computer.svg';

export {
  LOGO,
  LOGO_WITH_TEXT_WHITE,
  LOGO_WITH_TEXT_BLACK,
  LOGO_CONVERSATION,
  LOGO_CONVERSATION_NARROW,
  WELL_RATED_TUTOR,
  WELL_RATED_TUTOR_WITH_CIRCLE,
  THREE_PEOPLE,
  TROPHY_WITH_CIRCLE,
  CALENDAR_WITH_CIRCLE,
  POLICY_ICON,
  LOCATION_ICON,
  SHARE_ICON,
  SHIELD_ICON,
  LIGHTBULB_ICON,
  LOCATION_ICON_SVG,
  LEFT_ARROW_SVG,
  TUTOR_BOARD_SVG,
  UPLOAD_FILE_BACKGROUND_SVG,
  STUDENT_DESK_SVG,
  ACCOUNT_SVG,
  PHONE_SVG,
  EMAIL_SVG,
  ARROW_LEFT_SVG,
  ARROW_LEFT_DISABLED_SVG,
  ARROW_RIGHT_SVG,
  ARROW_RIGHT_DISABLED_SVG,
  QUOTES_SVG,
  ARROW_DOWN,
  HEART_INLINE,
  FACEBOOK,
  LINKEDIN,
  INSTAGRAM,
  TIK_TOK,
  UPLOAD,
  THREE_DOTS,
  DOWNLOAD,
  GOLDENCUP,
  ARROW_LEFT_BLACK_SVG,
  ARROW_RIGHT_BLACK_SVG,
  PAPERPLANE,
  STAR,
  TROPHY,
  BADGE_SOLID,
  CHANGE_ARROWS,
  CREDIT_CARD,
  PC_SOLID,
  GEAR_ICON,
  BRAIN_ICON,
  THREE_PEOPLE_ICON,
  RED_BLUE_DOT,
  CHECK_ICON,
  CHECK_PRIMARY_ICON,
  PHONE_WHITE_SVG,
  CHECKMARK_CIRCLE,
  CHECKMARK_BADGE,
  X_MARK_WHITE,
  X_MARK,
  CHECK_ICON_PRIMARY,
  GROUP_ICON_PRIMARY,
  SCHOOL_ICON_PRIMARY,
  SEARCH_CARD_ICON_PRIMARY,
  SPEECH_ICON_PRIMARY,
  ISB_LOGO,
  CALENDAR,
  GIVING,
  GIVING_WHITE,
  LAPTOP,
  CHECK_BLACK_ICON,
  LOGO_PREMIUM,
  DONATION_SUPPORT_BADGE,
  CALENDAR2,
  CERTIFICATE,
  ELEARNING,
  SETTINGS,
  STEPS,
  SUCCESS,
  SWAP,
  WHITEBOARD,
  THREE_WITH_FIVE_STARS,
  FLAG_DE,
  FLAG_EN,
  FLAG_UA,
  EMOJI_PHONE,
  EMOJI_ROCKET,
  REVIEW_PLATFORM_STARS,
  REVIEW_PLATFORM_VERIFIED,
  REGISTER_AS_ELITETUTOR_BADGE,
  CHAT,
  CLOCK,
  PAY_CASH,
  SHAKE_HANDS,
  TALK,
  THUMBS_DOWN,
  THUMBS_UP,
  WRITE_ON_COMPUTER,
};
